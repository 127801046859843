import Vue from "vue";

Vue.filter("formatNumber", function (value) {
  if (!value) return "0,00";

  return new Intl.NumberFormat("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
});

Vue.filter("roundNumber", function (value) {
  if (!value) return "0,00";
  return parseFloat(value).toFixed(2).replace(".", ",");
});

Vue.filter("stringNumber", function (value) {
  if (!value) return "0,00";

  return value.replace(".", ",");
});

Vue.filter("formatDate", function (value) {
  if (!value) return "";

  const d = new Date(value);

  if (isNaN(d.getTime())) return "";

  return new Date(
    d.getTime() + new Date().getTimezoneOffset() * 60000
  ).toLocaleDateString();
});

Vue.filter("joinValues", function (value) {
  if (!value) return "";

  return value.join(", ");
});

Vue.filter("formatDateTime", function (value) {
  if (!value) return "";

  const d = new Date(value);

  if (isNaN(d.getTime())) return "";

  return new Date(
    d.getTime() - new Date().getTimezoneOffset() * 60000
  ).toLocaleString();
});
