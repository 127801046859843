import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "home" */ "../views/Main.vue"),
    children: [
      {
        path: "/admin",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
        meta: {
          title: "Administração",
          admin: true,
        },
      },
      {
        path: "/home",
        alias: "",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Home.vue"),
        meta: { title: "Página Inicial" },
      },
      {
        path: "/empresas",
        component: () =>
          import(/* webpackChunkName: "empresas" */ "../views/Empresas.vue"),
        meta: { title: "Empresas" },
      },
      {
        path: "/vendedores",
        component: () =>
          import(
            /* webpackChunkName: "vendedores" */ "../views/Vendedores.vue"
          ),
        meta: { title: "Vendedores" },
      },
      {
        path: "/clientes",
        component: () =>
          import(/* webpackChunkName: "clientes" */ "../views/Clientes.vue"),
        meta: { title: "Clientes" },
      },
      {
        path: "/grupos",
        component: () =>
          import(/* webpackChunkName: "clientes" */ "../views/Grupos.vue"),
        meta: { title: "Grupos de Clientes" },
      },
      {
        path: "/canais",
        component: () =>
          import(/* webpackChunkName: "canais" */ "../views/Canais.vue"),
        meta: { title: "Canais" },
      },
      {
        path: "/pedidos",
        component: () =>
          import(/* webpackChunkName: "pedidos" */ "../views/Pedidos.vue"),
        meta: { title: "Pedidos" },
      },
      {
        path: "/recebimentos",
        component: () =>
          import(
            /* webpackChunkName: "recebimentos" */ "../views/Recebimentos.vue"
          ),
        meta: { title: "Recebimentos" },
      },
      // {
      //     path: '/pedidos-importar',
      //     component: () => import(/* webpackChunkName: "pedidos" */ '../views/PedidosImportar.vue'),
      //     meta: {title: "Pedidos não Importados"}
      // },
      {
        path: "/relatorio-receber",
        component: () =>
          import(
            /* webpackChunkName: "relatorio-receber" */ "../views/RelatorioReceber.vue"
          ),
        meta: { title: "Relatório de Pedidos a Receber" },
      },
      {
        path: "/relatorio-quitados",
        component: () =>
          import(
            /* webpackChunkName: "relatorio-quitados" */ "../views/RelatorioQuitados.vue"
          ),
        meta: { title: "Relatório de Pedidos Quitados" },
      },
      {
        path: "/relatorio-clientes",
        component: () =>
          import(
            /* webpackChunkName: "relatorio-clientes" */ "../views/RelatorioClientes.vue"
          ),
        meta: { title: "Relatório de Clientes" },
      },
      {
        path: "/relatorio-comissao-geral",
        component: () =>
          import(
            /* webpackChunkName: "relatorio-comissao-geral" */ "../views/RelatorioComissaoGeral.vue"
          ),
        meta: { title: "Relatório de Comissão Geral" },
      },
      {
        path: "/relatorio-comissao-vendedor",
        component: () =>
          import(
            /* webpackChunkName: "relatorio-comissao-vendedor" */ "../views/RelatorioComissaoVendedor.vue"
          ),
        meta: { title: "Relatório de Comissão por Vendedor" },
      },
      {
        path: "/relatorio-clientes-inativos",
        component: () =>
          import(
            /* webpackChunkName: "relatorio-clientes-inativos" */ "../views/RelatorioClientesInativos.vue"
          ),
        meta: { title: "Relatório de Clientes Inativos" },
      },
      {
        path: "/relatorio-clientes-industrias",
        component: () =>
          import(
            /* webpackChunkName: "relatorio-clientes-industrias" */ "../views/RelatorioClientesIndustrias.vue"
          ),
        meta: { title: "Relatório de Clientes Indústrias" },
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      guest: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("logged") == null) {
      return next();
    } else {
      return next({ path: "/home" });
    }
  } else {
    if (localStorage.getItem("logged") == null) {
      return next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      return next();
    }
  }
});

export default router;
