import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vmodal from "vue-js-modal";
import Toasted from "vue-toasted";

import Autocomplete from "@trevoreyre/autocomplete-vue";
import "./css/autocomplete.css";

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import "./filters";

Vue.config.productionTip = true;

Vue.use(Toasted, {
  theme: "toasted-primary",
  position: "bottom-right",
  duration: 4500,
});

Vue.use(Autocomplete);

Vue.component("multiselect", Multiselect);

Vue.use(vmodal, {
  dynamic: true,
  dynamicDefaults: {
    height: "auto",
    scrollable: true,
    width: "95%",
    classes: "padding-16",
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
