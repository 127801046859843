<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      tituloHeader: "Sistema Zanutto Representações",
    };
  },

  watch: {
    $route(to) {
      if (to.path == "/login") {
        this.logged = localStorage.getItem("logged") !== null;
      }
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  border-collapse: collapse;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html,
input {
  font-family: "Roboto", Arial, sans-serif;
}
body,
html {
  width: 100%;
  margin: 0;
  height: 100%;
}

body {
  background-color: #fff;
  margin: 0;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

#modals-container h1 {
  margin: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.header h1 {
  font-size: 2rem;
}
.header img {
  width: 200px;
}
.header .info {
  text-align: right;
}
/* The Close Button */
.close {
  height: 18px;
  width: 18px;
  line-height: 18px;
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.header button {
  margin-top: 0.4rem;
  padding: 2px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* For mobile phones: */
[class*="col-"] {
  width: 100%;
  float: left;
  padding: 6px;
}

.row {
  margin-left: -6px;
  margin-right: -6px;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.sm-divisor-horizontal > * + *:not([class="clear-both"]) {
  border-top: 1.2px solid #f0f0f0;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {
    width: 8.33%;
  }
  .col-2 {
    width: 16.66%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33%;
  }
  .col-5 {
    width: 41.66%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33%;
  }
  .col-8 {
    width: 66.66%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33%;
  }
  .col-11 {
    width: 91.66%;
  }
  .col-12 {
    width: 100%;
  }

  .header {
    text-align: inherit;
  }

  .sm-divisor-horizontal > * + *:not([class="clear-both"]) {
    border-top: 0;
  }
}

.clear-both {
  clear: both;
}

.menu a {
  color: #fff;
  display: block;
  padding: 10px 16px;
}

.divisor-horizontal > *:not(.no-divisor) + *:not(.clear-both):not(.no-divisor) {
  border-top: 1.2px solid #f0f0f0;
}

.divisor-vertical > * + *:not([class="clear-both"]) {
  border-left: 1.2px solid #f0f0f0;
}

.menu a:hover {
  background-color: #229fb9;
}

.padding-8 {
  padding: 16px;
}

.padding-16 {
  padding: 16px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.border-radius {
  border-radius: 16px;
  overflow: hidden;
}

tr:nth-child(2n + 3) {
  background: #f0f0f0;
}
tr:nth-child(even) {
  background: #fff;
}

th,
td {
  text-align: center;
  border-spacing: 0;
  padding: 8px 0px;
}

th + th {
  border-left: 1px solid #f0f0f0;
}

td + td {
  border-left: 1px solid #7e7e7e;
}

td.number {
  white-space: nowrap;
}

table td input {
  width: 100%;
  background: 0;
  padding: 6px 6px;
  border: 1px solid #ccc;
  margin: 0;
}

.number {
  width: 85px;
}

.porcentagem {
  max-width: 60px;
}

.text {
  max-width: 250px;
}

table select {
  background: 0;
  border: 1px solid #ccc;
  max-width: 120px;
}

button {
  padding: 0;
}

table button {
  border: 0;
  background: 0;
}

.short,
.short * {
  width: 80px;
}

i {
  vertical-align: middle;
}

i.small {
  font-size: 18px;
}

i.delete {
  color: #d31414;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.filtro-icon {
  padding: 0;
  height: 30px;
  width: 30px;
}

.filtro-label {
  display: block;
  font-size: 14px;
  padding-bottom: 2px;
}

.filtro-input {
  width: 100%;
  padding: 4px;
  border: 0;
  height: 30px;
  background: #fff;
  font-size: 100%;
}

button,
select {
  cursor: pointer;
}

select,
td input {
  font-size: 100%;
  height: 30px;
}

input {
  font-family: Arial, sans-serif;
}

.display-flex {
  display: flex;
}
.display-block {
  display: block;
}
.display-grid {
  display: grid;
}
.display-none {
  display: none;
}

.texto-verde {
  color: #1c6b13;
}

.border-color-azul-escuro {
  border: 0.5px solid #1789a0;
}

.border-color-vermelho {
  border: 2px solid red;
}

.bg-azul {
  background-color: #18adc6;
  color: #fff;
}

.bg-azul-escuro {
  background-color: #1789a0;
  color: #fff;
}

.scroll {
  overflow-x: auto;
}

.border-focus {
  border: 1px solid red;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@-moz-document url-prefix() {
  input[type="date"] {
    letter-spacing: -0.8px;
  }
}

::-webkit-clear-button {
  display: none; /* Hide the button */
  -webkit-appearance: none; /* turn off default browser styling */
}

input[type="date"] {
  -webkit-appearance: none;
  font-family: Arial;
}

/* PREFIX/ SUFIX on input */
.input-group {
  vertical-align: middle;
  display: flex;
}

.input-group-addon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #555;
  text-align: center;
  background-color: #eee;
  border-right: 0;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon + input {
  border-left: 0;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}
</style>
